import {getLanguageList, getSettings} from "../repositories/GeneralDataRepository";

import cookie from 'js-cookie';

const appStorage = {
    namespaced: true,
    state() {
        return {
            appDrawer: false,
            currentDrawerContent: null,
            language: 'Azərbaycanca',
            languageCode: 'az',
            loading: true,
            openDialogLanguage: false,
            dialogNotify: false,
            messageNotify: null,
            languages: [],
            settings: {}
        };
    },
    mutations: {
        setAppDrawer(state, payload) {
            state.appDrawer = payload;
        },

        setCurrentDrawerContent(state, payload) {
            state.currentDrawerContent = payload;
        },

        setDialogLanguage(state, payload) {
            state.openDialogLanguage = payload;
        },

        setLanguage(state, payload) {
            state.language = payload.name;
            state.languageCode = payload.code;

        },

        setLoading(state, payload) {
            state.loading = payload;
        },

        setDialogNotify(state, payload) {
            state.dialogNotify = payload;
        },

        setMessageNotify(state, payload) {
            state.messageNotify = payload;
        },
        setSettings(state, payload) {
            state.settings = payload;
        }
    },
    actions: {
        setLoading(context, payload) {
            context.commit('setLoading', payload)
        },
        async loadLanguages(context) {
            await getLanguageList().then(languages => {
                context.state.languages = languages;

                let lang = {};

                if (!context.state.languageCode) {
                    lang = languages[0];
                } else {
                    let filtered = languages.filter((l) => l.code === context.state.languageCode);
                    if (filtered.length > 0) {
                        lang = filtered[0]
                    } else {
                        lang = languages[0]
                    }
                }

                context.commit('setLanguage', lang);
                cookie.set('languageCode', lang.code);
                cookie.set('languageName', lang.name);
            })
        },
        async loadSettings(context) {
            await getSettings().then(settings => {
                context.state.settings = settings;
            })
        },
        async setLanguage(context, payload) {
            context.commit('setLanguage', payload);
            cookie.set('languageCode', payload.code);
            cookie.set('languageName', payload.name);
        }
    },
    getters: {}
}

export default appStorage;