<template>
    <div style="padding-top: 100px;">
        <div class="ps-contact">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
                <div class="ps-contact__content" v-if="user!==null">
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="ps-contact__info">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <h2 class="ps-contact__title">{{user.name}} {{user.surname}}</h2>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="ps-contact__work">
                                                    <strong>E-mail:</strong> {{user.email}}<br>
                                                    <strong>Telefon:</strong> {{user.phone}}
                                                </div>
                                            </div>
                                            <div class="col-md-3 text-right">
                                                <div class="ps-contact__email border-0">
                                                    <a href="#" @click.prevent="logoutUser"
                                                       class="text-center">Çıxış</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 ">

                            <router-view></router-view>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import {mapState} from "vuex";

    export default {
        name: "Profile",
        components: {Breadcrumb},
        computed: {
            ...mapState(
                {user: state => state.auth.user}
            ),
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/profile',
                        text: 'Profil'
                    },
                ];
            },

        },
        watch: {
            user(newData) {
                console.log(newData);
                if (newData === null || newData.length === 0) {
                    window.location.href = '/login';
                }
            }
        },
        methods: {
            logoutUser() {
                this.$store.dispatch('auth/logout');
            }
        },
    }
</script>

<style scoped>

</style>