<template>
    <div v-if="isVisible" class="modal fade show" id="popupLanguage" data-backdrop="static"
         data-keyboard="false"
         tabindex="-1" aria-hidden="true" style="display: block">
        <div class="modal-dialog modal-dialog-centered ps-popup--select" :class="`modal-${size}`">
            <div class="modal-content">
                <div class="modal-header">
                    {{title}}
                    <button class="close ps-popup__close" type="button" @click.prevent="$emit('modalClose')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="wrap-modal-slider container-fluid open">
                        <slot> </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="isVisible" class="modal-backdrop fade show"></div>
</template>

<script>
    export default {
        name: "ModalSlot",
        emits: ["modalClose"],
        props: [
            'isVisible', 'title', 'modalSize'
        ],
        computed: {
            size() {
                return this.modalSize || 'lg';
            }
        }
    }
</script>

<style scoped>

</style>