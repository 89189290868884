import homeStorage from "./home";
import appStorage from "./app";
import cartStorage from "./cart";
import wishListStorage from "./wishlist";
import {createStore} from "vuex";
import productStorage from "./product";
import authStorage from "./auth";

const store = createStore ({
    modules: {
        app: appStorage,
        home: homeStorage,
        cart: cartStorage,
        wishlist: wishListStorage,
        product: productStorage,
        auth: authStorage
    }
});


export default store;
