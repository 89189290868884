<template>
    <section :class="['ps-section--banner', sectionClass]">
        <div class="ps-section__overlay" v-show="loadingBanner">
            <div class="ps-section__loading"></div>
        </div>
        <div class="ps-swiper">
            <div class="swiper">
                <swiper :slides-per-view="1" navigation :pagination="{ clickable: true }"
                        :autoplay="{delay: 5000,}" :scrollbar="{ draggable: true }"
                        :space-between="0" :loop="true">
                    <swiper-slide v-for="(item, index) in listData" :key="index">
                        <div class="ps-banner--round" v-if="round">
                            <banner :banner="item"/>
                        </div>
                        <banner :banner="item" v-else/>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState} from 'vuex';
    import Banner from "./Banner";
    import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/swiper.scss';
    import 'swiper/components/navigation/navigation.scss';
    import 'swiper/components/pagination/pagination.scss';
    import 'swiper/components/scrollbar/scrollbar.scss';

    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

    export default {
        components: {Banner, Swiper, SwiperSlide},
        props: {
            listData: {
                type: Array,
                default: () => []
            },
            sectionClass: {
                type: String,
                default: ''
            },
            round: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loadingBanner: true
            };
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading
            })
        },
        mounted() {
            setTimeout(() => {
                this.loadingBanner = false;
            }, 1000);
        }
    };
</script>

<style lang="scss">

    @import "../../assets/style/style";
    @import "https://unpkg.com/swiper@7/swiper-bundle.min.css";

    /*.swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: 50%;
        width: calc(var(--swiper-navigation-size) / 44 * 27);
        height: var(--swiper-navigation-size);
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }


    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 10px;
        right: auto;
    }
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
        content: "prev";
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 10px;
        left: auto;
    }
    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
        content: "next";
    }
*/
</style>
