<template>
    <div class="ps-product ps-product--horizontal">
        <div class="ps-product__thumbnail">

            <a class="ps-product__image" :href="`/product/${product.slug}`" v-if="product.thumbnail">
                <figure>
                    <img :src="baseDomain + product.thumbnail.url" alt="alt"/>
                </figure>
            </a>
        </div>
        <div class="ps-product__content">
            <h5 class="ps-product__title">
                <a :href="`/product/${product.slug}`">{{ product.name }}</a>
            </h5>
            <p class="ps-product__desc">
                {{product.category.name}}
            </p>
            <div class="ps-product__meta" v-if="productPrice>0">
                <span
                        :class="[
                        'ps-product__price',
                        product.sale_price ? 'sale' : ''
                    ]"
                >
                    {{product.sale_price ? product.sale_price : productPrice}} AZN
                </span>
                <span class="ps-product__del" v-if="product.sale_price">{{ productPrice }} AZN</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {baseUrl} from "../../repositories/Repository";
    import {mapState} from "vuex";

    export default {
        props: {
            product: {
                type: Object
            }
        },
        data() {
            return {
                baseDomain: baseUrl
            };
        },
        computed:{
            ...mapState({
                taxIncluded: state => state.app.taxIncluded
            }),
            productPrice() {
                if (this.product.price <= 0) {
                    return 0;
                }return this.product.price;
            }
        }
    };
</script>
