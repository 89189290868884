<template>
    <section class="ps-about--info">
        <div class="container">
            <h2 v-if="title" class="ps-about__title" v-html="title"></h2>
            <p class="ps-about__subtitle">
                {{subtitle}}
            </p>
            <div class="ps-about__extent">
                <div class="row m-0">
                    <div class="col-12 col-sm-4 p-0" v-for="(item, index) in dataList" :key="index">
                        <div class="ps-block--about">
                            <div class="ps-block__icon">
                                <img :src="baseUrl + item.photo" :alt="item.title"/>
                            </div>
                            <h4 class="ps-block__title" v-html="item.title"></h4>
                            <div class="ps-block__subtitle" v-html="item.text"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {getBlocks} from "../../repositories/GeneralDataRepository";
    import {baseUrl} from '../../repositories/Repository';
    import {mapState} from "vuex";

    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            subtitle: {
                type: String,
                default: ''
            },
            block: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                baseUrl: baseUrl,
                dataList: []
            }
        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode
            })
        },
        async mounted() {
            await getBlocks(this.languageCode, this.block, false).then(data => {
                this.dataList = data;
            })
        }
    };
</script>
