<template lang="html">
    <ul :class="className">
        <li
                v-for="(item, index) in list"
                :class="[item.extraClass]"
                :key="index">
            <a
                    :href="item.url ? item.url : '#'"
                    :class="[item.linkClass]">
                <i v-if="item.icon" :class="item.icon"></i>
                {{ item.text }}
                <span class="has-badge" v-if="item.new">New</span>
            </a>
            <span class="sub-toggle" v-if="item.subMenu || item.megaContent">
                <i class="fa fa-chevron-down"></i>
            </span>
            <menu-renderer v-if="item.subMenu" :list="item.subMenu" :className="item.subClass" :promos="promos"/>

            <div class="mega-menu" v-else-if="item.megaContent">
                <div class="container">
                    <div class="mega-menu__widget" v-if="item.home">
                        <menu-mega-image :data-list="item.megaContent"/>
                    </div>
                    <div class="mega-menu__row" v-else>
                        <div v-for="(val, indexVal) in item.megaContent" :key="indexVal"
                             :class="['mega-menu__column', val.megaColumn && val.megaColumn]">
                            <template v-if="val.heading">
                                <h4>{{ val.heading }}</h4>
                                <menu-renderer :list="val.megaItems" className="sub-menu--mega" :promos="promos"/>
                            </template>
                            <template v-else>
                                <render-list v-if="val.megaItems" :list="val.megaItems"
                                             className="sub-menu--mega sub-menu--bold"/>
                                <div v-else-if="val.promo">
                                    <div class="ps-promo" v-for="(promo, indexPromo) in promos" :key="indexPromo">
                                        <promo-item :item="promo"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

        </li>
    </ul>
</template>

<script>

    import MenuMegaImage from "./MenuMegaImage";
    import PromoItem from "./PromoItem";

    export default {
        name: 'RenderList',
        components: {MenuMegaImage, PromoItem},
        props: {
            list: {
                type: Array,
                default: () => []
            },
            promos: {
                type: Array,
                default: () => []
            },
            className: {
                type: String,
                default: ''
            },
            active: {
                type: Number,
                default: -1
            }
        },
        data() {
            return {
                active_el: null
            }
        }
    };
</script>
