<template>
    <header :class="['ps-header ps-header--12 ps-header--mobile', {'ps-header--sticky': sticky}]">
        <div class="ps-header__middle">
            <div class="container">
                <div class="ps-logo">
                    <router-link to="/">
                        <img :src="logo" alt
                    /></router-link>
                </div>
                <div class="ps-header__right">
                    <ul class="ps-header__icons">
                        <li>
                            <a
                                class="ps-header__item open-search"
                                href="#"
                                @click.prevent="handleOpenDrawer('search')"
                                ><i class="fa fa-search"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import logo from "../../assets/img/logo.png";

export default {
    components: {
    },
    data() {
        return {
            logo: logo,
            sticky: false
        }
    },
    computed: {
        ...mapState({
            appDrawer: state => state.app.appDrawer
        })
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll > 700) {
                this.sticky = true;
            } else {
                this.sticky = false;
            }
        },
        handleOpenDrawer(drawer) {
            this.$store.commit('app/setCurrentDrawerContent', drawer);
            this.$store.commit('app/setAppDrawer', !this.appDrawer);
        }
    }
};
</script>
