<template>
    <div class="ps-category__wrapper">
        <div class="ps-category__type">
            <a v-for="(item, index) in shopType" :key="index" href="#" :class="{ active: item.name === type }"
               @click.prevent="getType = item.name">
                <img :src="item.icon" alt/>
            </a>
        </div>
        <div class="ps-category__sort">
            <span>Sıralama</span>
            <select v-model="sortType">
                <option v-for="item in  sortItems" :value="item.value" :key="item.value">{{item.text}}</option>
            </select>
        </div>
        <div class="ps-category__show">
            <span>Səhifədə</span>
            <select v-model="currentPageSize">
                <option v-for="item in  showItems" :value="item.value" :key="item.value">{{item.text}}</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'grid2'
            },
            pageSize: {
                type: Number,
                default: 6
            },
            sort: {
                type: String,
                default: 'Latest'
            },
            filter: {
                type: Boolean,
                default: false
            },
            listType: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                sortType: "recommended",
                currentPageSize: "12",
                shopType: [
                    {
                        icon: '/img/icon/bars.svg',
                        name: 'list'
                    },
                    {
                        icon: '/img/icon/grid2.svg',
                        name: 'grid2'
                    },
                    {
                        icon: '/img/icon/grid3.svg',
                        name: 'grid3'
                    },
                    {
                        icon: '/img/icon/grid4.svg',
                        name: 'grid4'
                    }
                ],
                sortItems: [
                    {
                        text: 'Məsləhət görülən',
                        value: 'recommended'
                    },
                    {
                        text: 'Sonuncular',
                        value: 'latest'
                    },
                    {
                        text: 'Reytinq',
                        value: 'rating'
                    },
                    {
                        text: 'Ucuzdan bahaya',
                        value: 'price_asc'
                    },
                    {
                        text: 'Bahadan ucuza',
                        value: 'price_desc'
                    }
                ],
                showItems: [
                    {
                        text: '6',
                        value: 6
                    },
                    {
                        text: '12',
                        value: 12
                    },
                    {
                        text: '24',
                        value: 24
                    },
                    {
                        text: '36',
                        value: 36
                    },
                    {
                        text: '48',
                        value: 48
                    }
                ],
                openSeparate: false
            };
        },
        computed: {
            getType: {
                set(value) {
                    this.handleChangeType(value);
                    return value;
                },
                get() {
                    return this.type;
                }
            },
            getSort: {
                set(value) {
                    this.handleChangeSort(value);
                    return value;
                },
                get() {
                    return this.sort;
                }
            },
            getPageSize: {
                set(value) {
                    this.handleChangePageZise(value);
                    return value;
                },
                get() {
                    return this.pageSize;
                }
            }
        },
        mounted() {
            if (this.listType && this.listType.length) {
                this.shopType = this.listType;
            }
        },
        methods: {
            handleChangeType(value) {
                if (value !== this.type) {
                    this.$emit('handleChangeType', value);
                }
            },
            handleChangePageZise(value) {
                if (value !== this.pageSize) {
                    this.$emit('handleChangePageZise', value);
                }
            },
            handleChangeSort(value) {
                if (value !== this.sort) {
                    this.$emit('handleChangeSort', value);
                }
            },
            separateChange() {
                this.openSeparate = !this.openSeparate;
                this.$emit('handleChangeFilter', this.openSeparate);
            }
        },
        watch: {
            sortType(){
                this.$emit('handleChangeSort', this.sortType);
            },
            currentPageSize(){
                this.$emit('handleChangePageSize', this.currentPageSize);
            }

        }
    };
</script>
