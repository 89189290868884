<template>
    <div>


        <section class="ps-section--category ps-category--image">
            <div class="ps-section__content">
                <div class="row">
                    <div class="col-6 col-sm-4" v-for="(item, index) in categoryImage"
                         :key="index">
                        <div class="ps-category__thumbnail">
                            <router-link class="ps-category__image" :to="item.link"><img
                                    :src="item.image" alt></router-link>
                            <div class="ps-category__content">
                                <router-link class="ps-category__name" :to="item.link">
                                    {{ item.name }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>


    export default {
        name: "Profile",
        computed: {
            categoryImage() {
                return [
                    {
                        "name": "Hesab məlumatları",
                        "image": "/img/promotion/facemask-cat.png",
                        "link": "/profile/details"
                    },
                    {
                        "name": "Sifarişlərim",
                        "image": "/img/promotion/uniforms-cat.png",
                        "link": "/profile/orders"
                    },
                    {
                        "name": " Seçilmiş məhsullar",
                        "image": "/img/promotion/protectcover-cat.png",
                        "link": "/profile/favorites"
                    },
                    {
                        "name": "Baxdığınız məhsullar",
                        "image": "/img/promotion/dental-cat.png",
                        "link": "/profile/viewHistory"
                    },
                    {
                        "name": "Ünvanlarım",
                        "image": "/img/promotion/dental-cat.png",
                        "link": "/profile/addresses"
                    },
                ];
            }
        },
    }
</script>

<style scoped>

</style>