<template>
    <div>


        <section-banner :listData="banners" :round="true"/>

        <div class="ps-home__content mt-5">
            <div class="ps-promo ps-not-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-4"
                             v-for="(item, index) in promotionTop"
                             :key="index">
                            <promo-item :item="item"/>
                        </div>
                    </div>
                </div>
            </div>

            <info-block-list title="Niyə Bouquet?" subtitle="Test text for substitle" block="home"/>

            <featured-product title="Ən çox satılan məhsullar" tag="top"/>

        </div>
    </div>
</template>

<script>
    import SectionBanner from "../layout/SectionBanner";
    import PromoItem from "../layout/PromoItem";
    import FeaturedProduct from "../layout/FeaturedProduct";
    import {getBlocks} from "../../repositories/GeneralDataRepository";
    import {baseUrl} from "../../repositories/Repository";
    import roundImg from "../../assets/img/round.png";
    import {mapState} from "vuex";
    import InfoBlockList from "../layout/InfoBlockList";

    export default {
        name: "HomePage",
        components: {InfoBlockList, SectionBanner, PromoItem, FeaturedProduct},
        data() {

            return {
                banners: [],
                promotionTop: [],
            };

        },
        computed: {
            ...mapState({
                'languageCode': state => state.app.languageCode
            })
        },
        async mounted() {
            this.$store.dispatch('app/setLoading', true);

            await getBlocks(this.languageCode, 'sliders', false).then(data => {
                if (data.length < 1) {
                    this.$router.push("/");
                } else {
                    const banners = [];

                    data.forEach(function (item) {
                        banners.push({
                            "title": item.title,
                            "desc": item.text,
                            "shop": "bg-warning",
                            "img": baseUrl + item.photo,
                            "link": item.link,
                            "round": roundImg,
                            "bgColor": "#fcfcfe",
                            "bannerRound": true,
                            "filter": "filter: invert(9%) sepia(19%) saturate(9%) hue-rotate(352deg) brightness(101%) contrast(91%);"
                        })
                    })

                    this.banners = banners;
                }
            })

            await getBlocks(this.languageCode, 'banners', true).then(data => {
                if (data.length < 1) {
                    this.$router.push("/");
                } else {
                    this.promotionTop = data;
                }
            })

            this.$store.dispatch('app/setLoading', false);

        }
    }
</script>

<style scoped>

</style>