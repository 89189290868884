import {addToChart, clearChart, getChart, removeFromChart} from "../repositories/UserRepository";


const cartStorage = {
    namespaced: true,
    state() {
        return {
            total: 0,
            cartItems: [],
            dialogAddCart: false,
            productOverview: null,
            aboutCart: null,
            dialogQuickview: false,
            dialogAddCart2: false,
            dialogRating: false
        }
    },
    mutations: {
        initCart(state, payload) {
            state.cartItems = payload;
        },

        setDialogAddCart2(state, payload) {
            state.dialogAddCart2 = payload;
        },

        setDialogAddCart(state, payload) {
            state.dialogAddCart = payload;
        },

        setProductSale(state, payload) {
            state.aboutCart = payload;
        },
        setTotal(state, payload) {
            state.total = payload;
        },

        setDialogQuickview(state, payload) {
            state.dialogQuickview = payload;
        },

        setProductOverview(state, payload) {
            state.productOverview = payload;
        },
        setDialogRating(state, payload) {
            state.dialogRating = payload;
        }
    },
    actions: {
        loadCart(context) {
            getChart(context.rootState.auth.token, context.rootState.auth.hash).then((cartList) => {
                context.commit('initCart', cartList)
            });
        },

        addProductToCart(context, {productId, count}) {
            addToChart(context.rootState.auth.token, context.rootState.auth.hash, productId, '{}', count, 'add').then(res => {
                if (res) {
                    context.dispatch('loadCart');
                }
            });
        },

        removeProductCart(context, productId) {
            removeFromChart(context.rootState.auth.token, context.rootState.auth.hash, productId).then(res => {
                if (res) {
                    context.dispatch('loadCart');
                }
            });
        },

        changeQuantityProductCart(context, {id, quantity}) {
            //id, quantity
            if (quantity === 0) {
                this.removeProductCart(context, id);
            } else {
                addToChart(context.rootState.auth.token, context.rootState.auth.hash, id, '{}', quantity, 'set').then(res => {
                    if (res) {
                        context.dispatch('loadCart');
                    }
                });
            }
        },

        clearCart(context) {
            clearChart(context.rootState.auth.token, context.rootState.auth.hash).then(res => {
                if (res) {
                    context.dispatch('loadCart');
                }
            });
        }
    },
    getters: {}
};

export default cartStorage;