<template>
    <footer class="ps-footer ps-footer--5 mt-5">
        <div class="container pt-5">
            <div class="ps-footer__middle">
                <div class="row ps-footer__box">
                    <div class="col-12 col-sm-4">
                        <div class="ps-footer--contact">
                            <h5 class="ps-footer__title">Kömək lazımdır?</h5>
                            <div class="ps-footer__fax"><i class="icon-telephone"></i>{{settings.contactPhone}}</div>
                            <p class="ps-footer__work" v-html="settings.workingHours"></p>
                            <hr>
                            <p>
                                <a class="ps-footer__email" :href="`mailto:${settings.contactEmail}`">
                                    <i class="icon-envelope"></i>{{settings.contactEmail}}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="ps-footer--address">
                            <div class="ps-footer__title">Ünvanımız</div>
                            <p v-html="settings.addressText"></p>
                            <p v-if="settings.addressCoordinates">
                                <a target="_blank" :href="`https://maps.google.com/?q=${settings.addressCoordinates}`">
                                    Xəritədə göstər
                                </a>
                            </p>
                            <ul class="ps-social">
                                <li v-if="settings.social_facebook">
                                    <a class="ps-social__link facebook" :href="settings.social_facebook">
                                        <i class="fa fa-facebook"> </i>
                                        <span class="ps-tooltip">Facebook</span>
                                    </a>
                                </li>
                                <li v-if="settings.social_instagram">
                                    <a class="ps-social__link instagram" :href="settings.social_instagram">
                                        <i class="fa fa-instagram"> </i>
                                        <span class="ps-tooltip">Instagram</span>
                                    </a>
                                </li>
                                <li v-if="settings.social_youtube">
                                    <a class="ps-social__link youtube" :href="settings.social_youtube">
                                        <i class="fa fa-youtube-play"> </i>
                                        <span class="ps-tooltip">Youtube</span>
                                    </a>
                                </li>


                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="ps-footer--newsletter">
                            <h5 class="ps-footer__title">Zəng sifariş edin</h5>
                            <p>Biz sizinlə əlaqə saxlayıb məhsullarla bağlı ətraflı məlumat təqdim edək</p>
                            <form @submit.prevent="handleCallRequest()">
                                <div class="ps-form--subscribe">
                                    <div class="ps-form__control">
                                        <input class="form-control ps-input" v-model="phoneNumber" type="tel"
                                               placeholder="Telefon nömrəsi" required>
                                        <button class="ps-btn ps-btn--warning">Göndər</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ps-footer--bottom">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <p>Copyright © 2016-2023 Bouquet. Bütün hüquqlar qorunur</p>
                    </div>
                    <div class="col-12 col-md-6 text-right">
                        <img src="/img/payment.png" alt/>
                        <img
                                class="payment-light"
                                src="/img/payment-light.png"
                                alt
                        />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

    import {mapState} from "vuex";
    import {addCallReqeust} from "../../repositories/UserRepository";

    export default {
        data() {
            return {
                phoneNumber: ''
            }
        }, computed: {
            ...mapState({
                settings: state => state.app.settings,
                token: state => state.auth.token,
                hash: state => state.auth.hash,
            }),
        },
        methods: {
            handleCallRequest() {
                if (this.phoneNumber !== '') {
                    addCallReqeust(this.token, this.hash, this.phoneNumber).then((res) => {
                        if (res) {
                            this.phoneNumber = '';
                            const message = {
                                icon: 'icon-shield-check',
                                title: "Uğurlu əməliyyat",
                                message: 'Sorğunuz qeydə alındı!',
                                error: false
                            };
                            this.$store.commit('app/setMessageNotify', message);
                            this.$store.commit('app/setDialogNotify', true);
                        } else {
                            const message = {
                                icon: 'fa fa-exclamation-triangle',
                                title: "Uğursuz əməliyyat",
                                message: 'Sorğunuz qeydə alınmadı!',
                                error: true
                            };
                            this.$store.commit('app/setMessageNotify', message);
                            this.$store.commit('app/setDialogNotify', true);
                        }
                    });
                }
            }
        }
    }
</script>
