<template>
    <div style="padding-top: 100px;">
        <div class="ps-account">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="card shadow">
                        <div class="card-header p-0">

                          <h2 class="p-4 m-0">Giriş</h2>
                        </div>
                        <div class="card-body p-5">

                            <div class="form-group">
                                <label for="loginEmail" class="ps-form__label">E-mail *</label>
                                <input
                                        class="form-control p-3"
                                        type="email"
                                        id="loginEmail"
                                        v-model="loginEmail"
                                        required
                                        @input="v$.loginEmail.$touch()"
                                        @blur="v$.loginEmail.$touch()"
                                />
                                <div class="text-danger" v-for="e in loginEmailErrors" :key="e">{{e}}</div>
                            </div>
                            <div class="form-group">
                                <label for="loginPassword" class="ps-form__label">Şifrə *</label>
                                <input
                                        class="form-control  p-3"
                                        v-model="loginPassword"
                                        id="loginPassword"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                        required
                                        @input="v$.loginPassword.$touch()"
                                        @blur="v$.loginPassword.$touch()"
                                />
                            </div>
                            <p v-if="loginError != null" class="alert alert-danger">
                                {{loginError}}
                            </p>
                            <div class="my-3">
                                <button class="ps-btn ps-btn--warning mr-4" @click="handleLogin()">
                                    Giriş
                                </button>

                              <router-link class="ps-account__link" to="/forget">
                                Şifrəmi unutmuşam
                              </router-link>
                            </div>
                        </div>
                        </div>
                    </div>

                  <div class="col-sm-6 ">

                    <div class="card shadow">
                      <div class="card-body p-5 text-center">

                        <img src="/img/solar-panel.png" style="width: 100px; margin-bottom: 20px;" alt="">
                        <h2>Hesabınız yoxdur?</h2>
                        <p class="h2">
                          İndi qeydiyyatdan keçin və funksiyalardan limitsiz istifadə haqqı qazanın.
                        </p>

                        <p>

                          <router-link class="ps-btn ps-btn--primary w-50" to="/register">
                            Qeydiyyat
                          </router-link>
                        </p>
                      </div>
                    </div>

                  </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {useVuelidate} from '@vuelidate/core';
    import {required, email} from '@vuelidate/validators';
    import {mapState} from "vuex";

    export default {
        layout: 'default',
        validations: {
            loginEmail: {required, email},
            loginPassword: {required}
        },
        setup() {
            return {v$: useVuelidate()}
        },
        data() {
            return {
                show1: false,
                loginEmail: null,
                loginPassword: null
            };
        },
        computed: {
            ...mapState({
                loginError: state => state.auth.loginError,
                user: state => state.auth.user
            }),
            loginEmailErrors() {
                const errors = [];
                this.v$.$errors.forEach(function (error) {
                        if (error.$property === "loginEmail") {
                            if (error.$validator === 'email') {
                                errors.push('E-mailin formatı doğru deyil');
                            }
                            if (error.$validator === 'required') {
                                errors.push('E-mail vacib bölmədir');
                            }
                        }
                    }
                )
                return errors;
            },
            loginPasswordErrors() {
                const errors = [];
                this.v$.$errors.forEach(function (error) {
                        if (error.$property === "loginPassword") {
                            if (error.$validator === 'required') {
                                errors.push('Şifrə vacib bölmədir');
                            }
                        }
                    }
                )
                return errors;
            }
            ,
        },
        methods: {
            async handleLogin() {
                this.v$.loginEmail.$touch();
                this.v$.loginPassword.$touch();
                console.log(this.loginPassword);
                if (!this.v$.$invalid) {
                    this.$store.commit('app/setLoading', true);
                    await this.$store.dispatch('auth/login', {email: this.loginEmail, password: this.loginPassword});
                    this.$store.commit('app/setLoading', false);
                }
            }
        },
        watch: {
            user(newValue) {
                if (newValue !== null) {

                    let l = localStorage.getItem("goBackAfterLogin");
                    localStorage.removeItem("goBackAfterLogin")
                    if (!l) {
                        l = '/';
                    }
                    window.location.href = l;
                }
            }
        }
    }
    ;
</script>
